import React from "react"
import { graphql,Link } from "gatsby"
import Layout from "components/layout"
import * as styles from "styles/tag.module.css"
import * as styles_detail from "styles/content-detail.module.css"
import Meta from "components/meta"

const tag_page  = ({ 
    pageContext , 
    node,
    data:{
      allMarkdownRemark: {
        totalCount,
        edges:blogs,
        tag
      },
    },
  }) => {

    
/*       process.env.NODE_ENV === 'production'
        ? node.filter((value) => ! value.frontmatter.published ) 
        : node */
      

  return(
  
  <Layout>
    <Meta
    title={`${pageContext.tag}`}
    />  

    <strong>{pageContext.tag} ( {totalCount} ) </strong>
    
    {blogs.map(
      ({
        node: {
          id,
          frontmatter: { title, date,tag },
          fields: { slug },
          excerpt,
        },
      }) => {

      return(
      
       //item
        <div key={id} className={styles.box}>
          <h2 className={styles.title}>
            <Link to={`/works${slug}`}>{title}</Link>
          </h2>
          <div class="content_date">
          <span className={styles_detail.content_date}>Posted on</span> {date}
        </div> 

        <div className={styles_detail.content_tag}>
        <span className={styles_detail.content_tag}>Tag : </span>
       <ul className={styles_detail.list}>

       {tag.map (tag =>(
           <li key={tag} className={styles_detail.item}>
           <Link to={`/works/tag/${tag}` } >{tag}</Link>
           </li>
       ))}
           </ul> 
           </div>
           <p>{excerpt}</p>
           </div>
           
        
      )})}
    
    </Layout>
    )}
  export default tag_page;
  

  export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date],order: DESC}
      filter: { frontmatter: { tag: { in : [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          html
          fields {
            slug
          }
          
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD(ddd)")
            published
            tag
          }
          excerpt(format:PLAIN,pruneLength: 200,truncate: true)
        }
      } }
    }`
  
  
